import { NgModule } from "@angular/core";
import { S25EventFormConfigListComponent } from "./s25.event.form.config.list.component";
import { S25TableModule } from "../../../s25-table/s25.table.module";
import { S25EventFormConfigComponent } from "./s25.event.form.config.component";
import { CommonModule } from "@angular/common";
import { S25EditableModule } from "../../../s25-editable/s25.editable.module";
import { S25ToggleButtonModule } from "../../../s25-toggle-button/s25.toggle.button.module";
import { S25EditableBooleanModule } from "../../../s25-editable/s25-editable-boolean/s25.editable.boolean.module";
import { S25DropdownPaginatedModule } from "../../../s25-dropdown/s25.dropdown.paginated.module";
import { S25DndSortableModule } from "../../../s25-dnd-sortable/s25.dnd.sortable.module";
import { S25CheckboxModule } from "../../../s25-checkbox/s25.checkbox.module";
import { FormsModule } from "@angular/forms";
import { S25TriggerRerenderModule } from "../../../s25-trigger-rerender/s25.trigger.rerender.module";

@NgModule({
    imports: [
        S25TableModule,
        CommonModule,
        S25EditableModule,
        S25ToggleButtonModule,
        S25EditableBooleanModule,
        S25DropdownPaginatedModule,
        S25DndSortableModule,
        S25CheckboxModule,
        FormsModule,
        S25TriggerRerenderModule,
    ],
    exports: [S25EventFormConfigComponent],
    declarations: [S25EventFormConfigListComponent, S25EventFormConfigComponent],
    providers: [S25EventFormConfigListComponent, S25EventFormConfigComponent],
})
export class S25EventFormConfigsModule {}
