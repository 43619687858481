import { Component, OnInit, ViewChild } from "@angular/core";
import { Table } from "../../s25-table/Table";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { Bind } from "../../../decorators/bind.decorator";
import { EventQuotaDetailedI, EventQuotaService } from "../../../services/event.quota.service";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { GenericTableListComponent } from "../../s25-table/generics/generic.table.list.component";
import { S25ItemOrganizationComponent } from "../../s25-item/item-object/s25.item.organization.component";
import { S25ItemSpaceComponent } from "../../s25-item/item-object/s25.item.space.component";
import { S25ModalComponent } from "../../s25-modal/s25.modal.component";
import { S25EditEventQuotasComponent } from "./s25.edit.event.quotas";
import { S25TableComponent } from "../../s25-table/s25.table.component";

@TypeManagerDecorator("s25-ng-event-quotas-list")
@Component({
    selector: "s25-ng-event-quotas-list",
    template: `
        <div *ngIf="isInit" class="top">
            <div class="header">
                <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add Quota</button>
            </div>
            <s25-ng-table
                [dataSource]="tableData"
                [hasRefresh]="true"
                [hasFilter]="true"
                [hasColumnChooser]="true"
                [columnSortable]="true"
            ></s25-ng-table>

            <s25-ng-modal
                #editQuota
                [title]="chosenQuota?.title"
                [type]="'save'"
                (save)="onSave()"
                (cancel)="quotaComponent.close('cancel')"
                [size]="'lg'"
            >
                <ng-template #s25ModalBody>
                    <s25-ng-edit-event-quotas
                        #quotaComponent
                        [isNew]="chosenQuota.isNew"
                        [model]="chosenQuota.model"
                    ></s25-ng-edit-event-quotas>
                </ng-template>
            </s25-ng-modal>

            <s25-ng-modal #deleteQuota [title]="'Confirm Deletion'" [type]="'deny'" [size]="'xs'">
                Are you sure that you want to delete this quota?
            </s25-ng-modal>
        </div>
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header > button {
            margin-bottom: 1em;
        }

        ::ng-deep s25-ng-event-quotas-list s25-item s25-popover > .ngInlineBlock {
            display: block !important;
            color: #3273a0;
            cursor: pointer;
        }

        ::ng-deep .nm-party--on s25-ng-event-quotas-list s25-item s25-popover > .ngInlineBlock {
            color: #6faee2;
        }

        .buttons {
            display: flex;
            gap: 0.5em;
            justify-content: end;
        }
    `,
})
export class S25EventQuotasListComponent implements OnInit {
    isInit = false;
    tableData: Table.DataSource;
    quotas = new Map<number, EventQuotaDetailedI>();
    chosenQuota: { isNew: boolean; title: string; model: EventQuotaDetailedI };

    @ViewChild(S25TableComponent) tableComponent: S25TableComponent;
    @ViewChild("editQuota") editQuotaModal: S25ModalComponent;
    @ViewChild("deleteQuota") deleteQuotaModal: S25ModalComponent;
    @ViewChild("quotaComponent") quotaComponent: S25EditEventQuotasComponent;

    ngOnInit() {
        this.tableData = this.getTableConfig();
        this.isInit = true;
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "name",
                    header: "Name",
                },
                {
                    id: "type",
                    header: "Type",
                    minWidth: 50,
                },
                {
                    id: "state",
                    header: "State",
                    minWidth: 60,
                },
                {
                    id: "included_reservations",
                    header: "Included Occurrences",
                },
                {
                    id: "occurrences",
                    header: "Max Occurr\u00ADences", // \u00AD is a soft hyphen
                },
                {
                    id: "time",
                    header: "Max Time",
                    content: { component: GenericTableListComponent },
                    minWidth: 60,
                },
                {
                    id: "duration",
                    header: "Duration",
                    minWidth: 60,
                },
                {
                    id: "organizations",
                    header: "Organizations",
                    content: { component: GenericTableListComponent },
                },
                {
                    id: "locations",
                    header: "Locations",
                    content: { component: GenericTableListComponent },
                },
                GenericTableButtonComponent.Column("Copy", this.onCopyClick, "outline"),
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        const quotas = (await EventQuotaService.getAll()) || [];
        for (let quota of quotas) this.quotas.set(quota.itemId, quota);

        return {
            rows: quotas.map(this.mapToRow),
        };
    }

    mapToRow(item: EventQuotaDetailedI): Table.Row {
        const organizations: Table.Cell[] = item.organizations.map(({ itemName, itemTypeId, itemId }) => ({
            component: S25ItemOrganizationComponent,
            inputs: { modelBean: { itemName, itemTypeId, itemId } },
        }));
        const locations: Table.Cell[] = item.locations.map(({ itemName, itemId, itemTypeId }) => ({
            component: S25ItemSpaceComponent,
            inputs: { modelBean: { itemName, itemTypeId, itemId } },
        }));
        return {
            id: item.itemId,
            name: item.itemName,
            cells: {
                name: {
                    text: item.itemName,
                },
                type: {
                    text: item.type.itemName.replace("Organization", "Org"),
                },
                state: {
                    text: item.active ? "Active" : "Inactive",
                },
                included_reservations: {
                    text: item.skipExisting ? "Single Event" : "All Occurrences",
                },
                occurrences: {
                    text: item.quantity || "",
                },
                time: {
                    inputs: {
                        items: S25Util.ISODurationToStr(item.maxTime)
                            .split(", ")
                            .map((item) => ({ text: item })),
                        hasDecoration: false,
                    },
                },
                duration: {
                    text: `${item.duration} ${item.durationTypeName}`,
                },
                organizations: {
                    inputs: { items: organizations },
                    textValue: item.organizations.map((org) => org.itemName).join(" "),
                },
                locations: {
                    inputs: { items: locations },
                    textValue: item.locations.map((org) => org.itemName).join(" "),
                },
            },
        };
    }

    async onCreateClick() {
        this.chosenQuota = { isNew: true, title: "Create New Quota", model: { itemName: "New Quota" } };
        await this.editQuotaModal.open();
    }

    @Bind
    async onCopyClick(row: Table.Row) {
        const quota = S25Util.deepCopy(this.quotas.get(row.id as number));
        delete quota.itemId; // Make sure it doesn't overwrite original
        quota.itemName += " copy";
        quota.addedLocations = quota.locations;
        quota.addedOrganizations = quota.organizations;
        quota.addedGroups = quota.securityGroups;
        this.chosenQuota = {
            isNew: true,
            title: `Copy Quota: ${row.name}`,
            model: quota,
        };
        await this.editQuotaModal.open();
    }

    @Bind
    onEditClick(row: Table.Row) {
        const quota = S25Util.deepCopy(this.quotas.get(row.id as number));
        this.chosenQuota = {
            isNew: false,
            title: `Edit Quota: ${row.name}`,
            model: quota,
        };
        this.editQuotaModal.open();
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        const yes = await this.deleteQuotaModal.open();
        if (yes) {
            await EventQuotaService.delete([row.id as number]);
            this.tableComponent.refresh();
        }
    }

    async onSave() {
        const ok = await this.quotaComponent.save();
        if (ok) {
            this.editQuotaModal.close();
            this.tableComponent.refresh();
        } else this.editQuotaModal.stopLoading();
    }
}
