//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewEncapsulation,
} from "@angular/core";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { PreferenceService } from "../../../../services/preference.service";

@TypeManagerDecorator("event-state-default")
@Component({
    selector: "event-state-default",
    template: `
        <div *ngIf="!this.initLoading">
            <div class="c-margin-bottom--single c-margin-top--quarter">
                <label for="eventState" class="ngBold c-margin-bottom--quarter"> Default Event State </label>
                <div>
                    <select
                        *ngIf="eventStateOptions"
                        class="ngListPageDropdown"
                        [(ngModel)]="this.eventState"
                        name="eventState"
                    >
                        <option *ngFor="let e of eventStateOptions" [ngValue]="e.val">{{ e.txt }}</option>
                    </select>
                </div>
            </div>
            <br />

            <div class="c-margin-bottom--single c-margin-top--single">
                <div class="ngBold c-margin-top--half">Copy Requestor</div>
                <div class="ngFinePrint c-margin-bottom--half">
                    'Yes' will copy the event requestor value onto the new event, 'No' will default to the current user.
                </div>
                <div class="c-margin-top--quarter">
                    <br />
                    <s25-toggle-button
                        [modelValue]="this.copyRequestor"
                        (modelValueChange)="this.onChange($event)"
                        [trueLabel]="'Yes'"
                        [falseLabel]="'No'"
                    ></s25-toggle-button>
                </div>
            </div>
            <div class="c-displayBlock c-padding-bottom--double">
                <button
                    class="aw-button aw-button--primary c-margin-top--single c-margin-right--quarter"
                    (click)="save()"
                >
                    {{ this.loading ? "Saving...." : "Save" }}
                </button>
                <button class="aw-button aw-button--outline c-margin-top--single" (click)="cancel()">Cancel</button>
            </div>
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventStateDefaultComponent implements OnInit {
    initLoading: boolean = true;
    loading: boolean = false;
    eventState: any;
    eventStateOptions = [
        { val: 0, txt: "Draft" },
        { val: 1, txt: "Tentative" },
        { val: 2, txt: "Confirmed" },
    ];
    initEventState: any;
    initCopyReq: boolean;
    copyRequestor: boolean;
    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        this.getPrefs();
        this.cd.detectChanges();
    }

    getPrefs() {
        return S25Util.all({
            prefs: PreferenceService.getPreferences(["SpbkEvState", "copy_requestor"], "S"),
        }).then((resp) => {
            let copyPref =
                resp.prefs.copy_requestor.value === "" ? false : S25Util.toBool(resp.prefs.copy_requestor.value);
            this.initEventState = this.eventState = parseInt(resp.prefs.SpbkEvState.value);
            this.initCopyReq = this.copyRequestor = copyPref;
            this.initLoading = false;
            this.cd.detectChanges();
        });
    }

    onChange(event: boolean) {
        this.copyRequestor = event;
    }

    save() {
        this.loading = true;
        this.cd.detectChanges();

        let promiseArr = [];
        this.initEventState !== this.eventState &&
            promiseArr.push(PreferenceService.setPreference("SpbkEvState", this.eventState, "S"));
        this.initCopyReq !== this.copyRequestor &&
            promiseArr.push(PreferenceService.setPreference("copy_requestor", this.copyRequestor, "S"));
        if (promiseArr) {
            return S25Util.all(promiseArr).then((resp) => {
                this.loading = false;
                this.cd.detectChanges();
            });
        }
    }

    cancel() {
        this.ngOnInit();
        this.cd.detectChanges();
    }
}
