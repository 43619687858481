import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    EventCreationFormService,
    EventFormConfigPutQuery,
    EventFormConfigResponseItem,
} from "../../../../services/event.creation.form.service";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";

@TypeManagerDecorator("s25-ng-event-form-config")
@Component({
    selector: "s25-ng-event-form-config",
    template: `
        <div *ngIf="isInit">
            <div s25-ng-auto-scroll>
                <div class="configSettings">
                    <h5>Configuration Settings</h5>

                    <label>
                        <span>Configuration Name:</span>
                        <s25-ng-editable-text
                            [allowEmpty]="false"
                            [(val)]="config.configuration_name"
                        ></s25-ng-editable-text>
                    </label>

                    <label>
                        <span>Help Message:</span>
                        <s25-ng-editable-richtext [(val)]="config.configuration_message"></s25-ng-editable-richtext>
                    </label>

                    <label><span>Default Config:</span></label>
                    <s25-toggle-button
                        [disabled]="!canChangeIsDefault"
                        [(modelValue)]="config.is_default"
                    ></s25-toggle-button>

                    <label>
                        <span>Automatically Add Custom Attributes:</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.auto_add_cust_atrb"></s25-toggle-button>

                    <label>
                        <span>Allow Spans Midnight Events:</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_spans_midnight"></s25-toggle-button>

                    <label>
                        <span>Show All Day Toggle:</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_all_day"></s25-toggle-button>

                    <label>
                        <span>Allow Add/Remove Segments:</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_multi_profile"></s25-toggle-button>

                    <label>
                        <span>Use Recommended Resources (instead of resource search):</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_recommended_resource"></s25-toggle-button>

                    <label>
                        <span>Allow Silent Save (skip Event Save and Wizard Config emails):</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_silent_save"></s25-toggle-button>

                    <div class="configRow">
                        <label id="default-event-type-label"><span>Default Event Type:</span></label>
                        <s25-ng-dropdown-search-criteria
                            [type]="'eventTypes'"
                            [(chosen)]="config.default_event_type"
                            aria-labelledby="default-event-type-label"
                        ></s25-ng-dropdown-search-criteria>
                        <button class="aw-button aw-button--outline" (click)="onResetDefaultEventType()">Remove</button>
                    </div>

                    <label>
                        <span>Allow Repeating Pattern:</span>
                    </label>
                    <s25-toggle-button [(modelValue)]="config.has_pattern"></s25-toggle-button>

                    <label>
                        <span>Repeating Pattern Help Message:</span>
                        <s25-ng-editable-richtext [(val)]="config.pattern_help"></s25-ng-editable-richtext>
                    </label>
                </div>

                <h5>Item Settings</h5>
                <div s25-ng-dnd-sortable [items]="config.item">
                    <div
                        *ngFor="let item of config.item; let i = index; trackBy: rerenderOnReorder"
                        s25-ng-dnd-sortable-item
                        [index]="i"
                        class="configItemRow"
                    >
                        <svg class="c-svgIcon">
                            <title>Move</title>
                            <use
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#drag--indicator"
                            ></use>
                        </svg>
                        <h6 class="ngBold">{{ item.formalName }}</h6>
                        <label class="displayName">
                            Display Name:
                            <s25-ng-editable-text [(val)]="item.name"></s25-ng-editable-text>
                        </label>
                        <label>
                            Visibility Level:
                            <select class="cn-form__control" [(ngModel)]="item.visibility">
                                <option value="invisible">Not Visible</option>
                                <option value="visible">View Only</option>
                                <option value="editable">Editable</option>
                                <option value="required">Required</option>
                            </select>
                        </label>
                        <label *ngIf="item.id !== 'affirmation'">
                            <s25-ng-checkbox [(modelValue)]="item.autoOpen"></s25-ng-checkbox>
                            Auto Open Help Message
                        </label>
                        <label class="helpMessage">
                            {{ item.id !== "affirmation" ? "Help Message:" : "Message" }}
                            <s25-ng-editable-richtext
                                [(val)]="item.message"
                                *s25-ng-trigger-rerender="i"
                            ></s25-ng-editable-richtext>
                        </label>
                    </div>
                </div>
            </div>

            <div class="buttons">
                <button class="aw-button aw-button--primary" (click)="onSave()">Save</button>
                <button class="aw-button aw-button--outline cancel" (click)="onCancel()">Cancel</button>
            </div>
        </div>
    `,
    styles: `
        label,
        label > span {
            display: block;
        }

        label {
            margin-bottom: 1em;
        }

        s25-toggle-button {
            display: block;
            margin-top: -1em;
            margin-bottom: 1em;
        }

        s25-ng-dropdown-search-criteria {
            display: inline-block;
            min-width: 15em;
        }

        s25-ng-dropdown-search-criteria + button {
            margin-left: 1em;
        }

        .configRow {
            margin-bottom: 2em;
        }

        .configItemRow {
            display: grid;
            grid-template-columns: 20px 150px 1fr 1fr 1fr;
            grid-template-rows: auto auto;
            column-gap: 1.5em;
            align-items: start;
            padding-top: 0.5em;
            border-bottom: 1px solid #ddd;
        }

        .configItemRow > .helpMessage {
            grid-row-start: 3;
            grid-column: 3 / span 3;
        }

        .displayName {
            min-width: 0;
        }

        .buttons {
            padding-top: 1em;
            display: flex;
            gap: 0.5em;
            position: sticky;
            bottom: 0;
            width: fit-content;
            z-index: 10;
        }

        .buttons .cancel:not(:hover, :focus) {
            background-color: white !important;
        }

        ::ng-deep #s25.nm-party--on .buttons .cancel:not(:hover, :focus) {
            background-color: #3d3d46 !important;
        }

        ::ng-deep
            s25-ng-event-form-config
            s25-ng-editable-richtext
            .s25-editable-shared-container.c-editable-text.editable.editable-click {
            height: 5em;
            overflow: hidden;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventFormConfigComponent implements OnInit {
    @Input() id?: number;
    @Input() mode: "create" | "copy" | "edit" = "edit";

    @Output() saved = new EventEmitter<void>();
    @Output() cancelled = new EventEmitter<void>();

    isInit = false;
    config: Config;
    canChangeIsDefault = true;

    constructor(private changeDetector: ChangeDetectorRef) {}

    async ngOnInit() {
        this.config = this.mode === "create" ? this.defaultConfig : await this.getConfig(this.id);
        this.canChangeIsDefault = !this.config.is_default; // Cannot unset default. You have to set another config to default instead
        this.isInit = true;
        this.changeDetector.detectChanges();
    }

    async getConfig(id: number): Promise<Config> {
        const data = await EventCreationFormService.getConfig(id);
        return {
            configuration_id: this.mode === "copy" ? null : data.configuration_id,
            configuration_name: (data.configuration_name || "") + (this.mode === "copy" ? " copy" : ""),
            configuration_message: data.configuration_message || "",
            auto_add_cust_atrb: !!data.auto_add_cust_atrb,
            has_spans_midnight: !!data.has_spans_midnight,
            has_all_day: !!data.has_all_day,
            has_multi_profile: !!data.has_multi_profile,
            has_recommended_resource: !!data.has_recommended_resource,
            has_silent_save: !!data.has_silent_save,
            has_pattern: !!data.has_pattern,
            pattern_help: data.pattern_help || "",
            is_default: !!data.is_default,
            default_event_type: data.default_event_type,
            item: this.extractItems(data.item),
        };
    }

    getVisibility(item: EventFormConfigResponseItem): ConfigItem["visibility"] {
        if (!item.visible) return "invisible";
        if (!!item.no_edit) return "visible";
        if (!!item.required) return "required";
        return "editable";
    }

    extractItems(items: EventFormConfigResponseItem[]): ConfigItem[] {
        return items.map((item) => ({
            id: item.item_name,
            formalName: EventCreationFormService.ConfigFormItemData[item.item_name].display,
            name: item.item_display,
            visibility: this.getVisibility(item),
            autoOpen: !!item.message_open,
            message: item.item_message ?? "",
            initialOrder: item.sort_order ?? EventCreationFormService.ConfigFormItemData[item.item_name].order,
        }));
    }

    get defaultConfig(): Config {
        return {
            configuration_id: null,
            configuration_name: "New Config",
            configuration_message: "",
            auto_add_cust_atrb: false,
            has_spans_midnight: false,
            has_all_day: false,
            has_multi_profile: false,
            has_recommended_resource: false,
            has_silent_save: false,
            has_pattern: false,
            pattern_help: "",
            is_default: false,
            default_event_type: null,
            item: Object.entries(EventCreationFormService.ConfigFormItemData)
                .filter(([_, item]: any) => !item.deprecated)
                .map(([id, item]) => ({
                    id: id as keyof typeof EventCreationFormService.ConfigFormItemData,
                    formalName: item.display,
                    name: item.display,
                    visibility: "invisible",
                    autoOpen: false,
                    message: "",
                    initialOrder: item.order,
                })),
        };
    }

    onResetDefaultEventType() {
        this.config.default_event_type = null;
        this.changeDetector.detectChanges();
    }

    async onSave() {
        if (!this.validate(this.config)) return;
        const payload = this.convertConfig(this.config);
        const ok = await EventCreationFormService.putConfig(payload, this.config.configuration_id).catch((error) => {
            S25Util.showError(error);
        });
        if (!ok) return;
        this.saved.emit();
    }

    onCancel() {
        this.cancelled.emit();
    }

    convertConfig(config: Config): EventFormConfigPutQuery {
        return {
            root: {
                config: {
                    configuration_message: config.configuration_message,
                    has_recommended_resource: config.has_recommended_resource ? 1 : 0,
                    has_pattern: config.has_pattern ? 1 : 0,
                    is_default: config.is_default ? 1 : 0,
                    has_multi_profile: config.has_multi_profile ? 1 : 0,
                    has_silent_save: config.has_silent_save ? 1 : 0,
                    pattern_help: config.pattern_help,
                    has_spans_midnight: config.has_spans_midnight ? 1 : 0,
                    auto_add_cust_atrb: config.auto_add_cust_atrb ? 1 : 0,
                    configuration_name: config.configuration_name,
                    has_all_day: config.has_all_day ? 1 : 0,
                    default_event_type: config.default_event_type,
                    configuration_id: config.configuration_id,
                },
                item: config.item
                    .map((item, i) => [item, i])
                    .map(([item, i]: [ConfigItem, number]) => ({
                        message_open: item.autoOpen ? 1 : 0,
                        item_display: item.name,
                        no_edit: item.visibility === "visible" ? 1 : 0,
                        item_name: item.id,
                        required: item.visibility === "required" ? 1 : 0,
                        item_message: item.message,
                        sort_order: i + 1,
                        visible: item.visibility !== "invisible" ? 1 : 0,
                    })),
            },
        };
    }

    validate(config: Config): boolean {
        const typeItem = config.item.find((item) => item.id === "type");
        if (
            !config.default_event_type &&
            typeItem &&
            typeItem.visibility !== "required" &&
            typeItem.visibility !== "editable"
        ) {
            alert(
                `The ${typeItem.name} form item is not editable and requires a default value. Please set a default for this item.`,
            );
            return false;
        }
        return true;
    }

    // This is needed to avoid the list jumping down when dragging an item up
    rerenderOnReorder(index: number, item: ConfigItem) {
        return `${index} ${item.id}`;
    }
}

interface Config {
    configuration_id: number;
    configuration_name: string;
    configuration_message: string;
    auto_add_cust_atrb: boolean;
    has_spans_midnight: boolean;
    has_all_day: boolean;
    has_multi_profile: boolean;
    has_recommended_resource: boolean;
    has_silent_save: boolean;
    has_pattern: boolean;
    pattern_help: string;
    item: ConfigItem[];
    is_default: boolean;
    default_event_type: {
        itemId: number;
        itemName: string;
    };
}

interface ConfigItem {
    id: keyof typeof EventCreationFormService.ConfigFormItemData;
    formalName: string;
    name: string;
    visibility: "required" | "editable" | "visible" | "invisible";
    autoOpen: boolean;
    message: string;
    initialOrder: number;
}
